/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hyludpdyd6.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:ef76155c-c24f-4cf5-a367-5f627c72b275",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_raqYDN2I1",
    "aws_user_pools_web_client_id": "5aa6j2ou0obqfvhfcdlu2bt38p",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "html-material",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
